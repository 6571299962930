import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./core/auth/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/home-page/home-page.module").then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: "occurrences",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/occurrences/occurrences.module").then(
        (m) => m.OccurrencesModule
      ),
  },
  {
    path: "occurrences-grouped",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/occurrences-grouped/occurrences-grouped.module").then(
        (m) => m.OccurrencesGroupedModule
      ),
  },
  {
    path: "nonCompliance",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/non-compliance/noncompliance.module").then(
        (m) => m.NoncomplianceModule
      ),
  },
  {
    path: "ombudsman",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/ombudsman/ombudsman.module").then(
        (m) => m.OmbudsmanModule
      ),
  },
  {
    path: "settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "monitoring",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/monitoring/monitoring.module").then(
        (m) => m.MonitoringModule
      ),
  },
  {
    path: "points",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/points/points.module").then((m) => m.PointsModule),
  },
  {
    path: "monitoring-mpu",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/monitoring-mpu/monitoring-mpu.module").then(
        (m) => m.MonitoringMpuModule
      ),
  },
  {
    path: "monitoring-mpu",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/monitoring-mpu/monitoring-mpu.module").then(
        (m) => m.MonitoringMpuModule
      ),
  },
  {
    path: "404",
    component: PageNotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "/404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}


<!--template web-->
<mat-drawer-container class="sidenav-container ">
  <mat-drawer mode="side" class="side-bar mat-elevation-z4 scroll-bar scroll shadow" opened style="width: 64px;">
    <mat-toolbar color="primary" class="mat-elevation-z1">
      <a routerLink="/home/"> <img class="logo" src="../../../assets/img/logo-sm-mp.svg"> </a>
    </mat-toolbar> <!--Logo-->

    <mat-nav-list role="navigation" class="side-navigation h-content-100">

      <ng-container *ngIf="hasPermission(['maria-da-penha'])">
        <mat-divider></mat-divider>
      </ng-container>

      <mat-menu #menuList="matMenu" class="text-justify ">
        <div class="bg">
        <div style="width: 100%; cursor: pointer" routerLink="/occurrences/list" class="{{ router.url === '/occurrences/list'
                             || router.url === '/occurrences/' + occurrence? 'isActive' : ''}}">
          <a matTooltip="Lista"
             routerLink="/occurrences/list"
             routerLinkActive="active-list-item" #rla="routerLinkActive"
             *ngIf="hasPermission(['list-occurrences'])">
            <mat-icon matListIcon svgIcon="list-occurrences" class="align-icon"
            ></mat-icon>
            <span>Lista</span>
          </a>
        </div>

        <ng-container *ngIf="hasPermission(['list-occurrences'])">
          <mat-divider></mat-divider>
        </ng-container>

        <div style="width: 100%; cursor: pointer" routerLink="/occurrences-grouped/list-grouped" class="{{ router.url === '/occurrences-grouped/list-grouped'
                             || router.url === '/occurrences-grouped/grouped/' + occurrenceGroup? 'isActive' : ''}}">
          <a matTooltip="Lista agrupada"
             routerLink="/occurrences-grouped/list-grouped"
             routerLinkActive="active-list-item" #rla="routerLinkActive"
             *ngIf="hasPermission(['list-occurrences'])">
            <mat-icon matListIcon svgIcon="list-occurrences-grouped" class="align-icon"
            ></mat-icon>
            <span>Lista agrupada</span>
          </a>
        </div>
        </div>
      </mat-menu>

      <ng-container *ngIf="hasPermission(['map-monitoring'])">
        <mat-divider></mat-divider>
      </ng-container>

      <a mat-list-item
         routerLink="/occurrences/list"
         routerLinkActive="active-list-item"
         #rla="routerLinkActive"
         matTooltip="Relatórios"
         *ngIf="hasPermission(['list-occurrences'])">
        <mat-icon  matListIcon svgIcon="list-occurrences" [color]="rla.isActive ? 'primary' : ''">
        </mat-icon>
      </a>

      <ng-container *ngIf="hasPermission(['map-monitoring'])">
        <mat-divider></mat-divider>
      </ng-container>

      <a mat-list-item
         routerLink="/monitoring/map" routerLinkActive="active-list-item" #rla="routerLinkActive"
         matTooltip="Monitorar Ocorrências no Mapa"
         *ngIf="hasPermission(['map-monitoring'])">
        <mat-icon matListIcon svgIcon="painel" [color]="rla.isActive ? 'primary' : ''">
        </mat-icon>
      </a>

      <ng-container *ngIf="hasPermission(['mpu'])">
        <mat-divider></mat-divider>
      </ng-container>

      <a mat-list-item
         routerLink="/nonCompliance/list"
         routerLinkActive="active-list-item" #rla="routerLinkActive"
         matTooltip="Usuário escolar"
         *ngIf="hasPermission(['mpu'])">
        <mat-icon matListIcon svgIcon="user-menu" [color]="rla.isActive && 'primary'"></mat-icon>
      </a>

      <ng-container
        *ngIf="hasPermission(['system-user-config', 'offices-config', 'occurrences-type-config', 'mobile-quiz', 'integrations-config'])">
        <mat-divider></mat-divider>
      </ng-container>

      <a mat-list-item routerLink="/points/map" routerLinkActive="active-list-item"
         #rla="routerLinkActive"
         matTooltip="Cadastrar escola"
         *ngIf="hasPermission(['map-points'])">
        <mat-icon matListIcon svgIcon="school" [color]="rla.isActive && 'primary'"></mat-icon>
      </a>

      <ng-container *ngIf="hasPermission(['map-points'])">
        <mat-divider></mat-divider>
      </ng-container>

      <a mat-list-item routerLink="/settings/users" routerLinkActive="active-list-item"
         #rla="routerLinkActive"
         matTooltip="Configurar Usuários"
         *ngIf="hasPermission(['system-user-config'])">
        <mat-icon matListIcon svgIcon="cognito-user" [color]="rla.isActive && 'primary'"></mat-icon>
      </a>

      <a mat-list-item routerLink="/settings/occurrences" routerLinkActive="active-list-item"
         #rla="routerLinkActive"
         matTooltip="Configurar Tipos de Ocorrências"
         *ngIf="hasPermission(['occurrences-type-config'])">
        <mat-icon matListIcon svgIcon="occurrences-type-configDefault" [color]="rla.isActive && 'primary'"></mat-icon>
        <!--        <h3 mat-line>Tipos de ocorrências</h3>-->
      </a>

      <a mat-list-item routerLink="/settings/status-occurrences" routerLinkActive="active-list-item"
         #rla="routerLinkActive"
         matTooltip="Configurar Status de Ocorrências"
         *ngIf="hasPermission(['occurrences-status-config'])">
        <mat-icon matListIcon svgIcon="occurrences-status-configDefault" [color]="rla.isActive && 'primary'"></mat-icon>
      </a>

    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>
    <mat-toolbar color="primary" class="mat-elevation-z4">

      <div class="col d-flex justify-content-end" style="width: 100%;">
        <button class="btn-acount" mat-icon-button matTooltip="Conta" [matMenuTriggerFor]="belowMenu">
          <div class="img">
            <img src="" alt="">
          </div>
        </button> <!-- button menu estilizado com a foto-->
        <mat-menu #belowMenu="matMenu">
          <div class="space"></div>
          <div class="menu-open">
            <h5 class="mb-3">{{authService.userName}}</h5>

            <span class="mb-3" style="cursor: pointer" (click)="editUser()">
              <mat-icon class="mr-2" svgIcon="pencil"></mat-icon>Editar Perfil
            </span>

            <span class="mb-3" style="cursor:pointer;" (click)="openModal()">
              <mat-icon class="mr-2" svgIcon="information"></mat-icon>
              Sobre o sistema
            </span>

            <span class="mb-3" style="cursor:pointer;" (click)="openModal()">
              <mat-icon class="mr-2" svgIcon="help"></mat-icon>
              Ajuda
            </span>

            <span class="mb-3" style="cursor:pointer;" (click)="logoutBtn()" matTooltip="Fazer logout">
              <mat-icon class="mr-2">exit_to_app</mat-icon>
              Fazer logout
            </span>


          </div>
        </mat-menu> <!--conteudo do menu depois de aberto-->
      </div>
    </mat-toolbar>

    <div class="site-content">
      <ng-content></ng-content>
    </div>

  </mat-drawer-content>
</mat-drawer-container>

// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './config/default/aws-config'
import {environment} from './app/pages/environments/environment';


enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

environment.version = 'D - 2.2'

// DESENVOLVIMENTO
Amplify.configure(awsmobile);

Amplify.configure({
  aws_appsync_graphqlEndpoint: awsmobile.aws_appsync_graphqlEndpoint,
  aws_appsync_region: awsmobile.aws_project_region,
  aws_appsync_authenticationType: awsmobile.aws_appsync_authenticationType,
  aws_appsync_apiKey: awsmobile.aws_appsync_apiKey
});

Amplify.configure({
  Auth: {
    identityPoolId: awsmobile.identityPoolId,
    region: awsmobile.region,
    userPoolId: awsmobile.aws_user_pools_id,
    userPoolWebClientId: awsmobile.aws_user_pools_web_client_id
  },
  Storage: {
    AWSS3: {
      bucket: awsmobile.aws_user_files_s3_bucket,
      region: awsmobile.aws_user_files_s3_bucket_region,
    }
  },
  API: {
    graphql_headers: async () => {
      return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
    }
  }
});


import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {fromPromise} from 'rxjs/internal-compatibility';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

import {graphqlOperation} from 'aws-amplify';
import {AmplifyService} from 'aws-amplify-angular';

import {AppCommon} from '../../../shared/model/common';
import {Ticket} from '../../../shared/model/ticket';
import {AuthService} from '../../../core/auth/auth.service';
import {CacheService} from '../../cache/cache.service';

import {getOmbudsmanTicket} from '../../../../graphql/queries';
import {addNoteToTicket, updateOmbudsmanTicket, updateOmbudsmanTicketProtocol} from '../../../../graphql/mutations';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-form-auditing',
  templateUrl: './form-omdubsman.component.html',
  styleUrls: ['./form-omdubsman.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'dd/mm/AAAA',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'DD/MM/YYYY',
        }
      }
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class FormOmbudsmanComponent implements OnInit {
  static ticketEmitter = new EventEmitter();

  loading = true;
  spinnerLoading = false;
  loadingEditForm = false;
  canEdit = false;
  editForm: FormGroup;
  noteForm: FormGroup;
  protocolForm: FormGroup;
  loadingProtocolForm = false;
  labelNote: string;
  ticket = new Ticket();
  showingIdentity = false; // se usuario solicitou sigilo na identidade
  statusOptions: Array<{name: string }>;
  minDate = new Date(2018, 1, 1);
  maxDate = new Date(2120, 12, 31);
  date = new FormControl(moment(['dd/mm/yyyy']));

  constructor(
    public appCommon: AppCommon,
    private amplifyService: AmplifyService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private cacheService: CacheService,
  ) {

    // busca os status de ocorrencias do usuario e preencher o select
    this.authService.occurrencesStatus.subscribe(status => {
        this.statusOptions = status;
      }
    );

    // Pega id da URL e carrega usuario
    this.activateRoute.params.subscribe(params => {
      const id = params.id;
      this.ticket.id = id;

      if (id !== undefined && id !== null) {
        fromPromise(this.amplifyService.api().graphql(graphqlOperation(getOmbudsmanTicket, {id})))
          .subscribe(
            resp => {
              const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
              this.ticket = result.data.getOmbudsmanTicket;
              this.ticket.priority = this.appCommon.priorityEnum(this.ticket.priority);

              this.resetForm();
              this.checkDisableNote();

              this.noteForm.controls.id.setValue(this.ticket.id);

              this.loading = false;

              FormOmbudsmanComponent.ticketEmitter.emit(this.ticket);

              // habilita o noteForm para ocorrências em aberto
              if (!this.ticket.closed) {
                this.noteForm.enable();
              }
            },
            error => console.log(error)
          );

      } else {
        this.loading = false;
      }
    });

    // busca as telas que o usuario tem permissao
    this.authService.permissions.subscribe(permissions => {
        const permission = permissions.find(item => item.name === 'ombudsman');
        if (permission) {
          this.canEdit = permission.action === 'rw';
        }
      }
    );
  }

  ngOnInit() {
    this.initForm();
  }

  /**
   * Desativa formulario e volta os valores iniciais
   */
  resetForm() {
    this.editForm.reset(Object.assign({}, this.ticket));
    this.editForm.disable();

    if (this.ticket.closed || !this.canEdit) {
      this.noteForm.disable();
    }
  }

  /**
   * Verifica e desabilita formulario de adicionar nota se o ticket esta fechado
   */
  private checkDisableNote() {
    if (this.ticket.status === 'CONCLUÍDO' || this.ticket.status === 'CONCLUIDO') {
      this.noteForm.disable();
    }
  }

  /**
   * Cria o formulario/controle
   */
  initForm() {
    this.editForm = this.formBuilder.group({
      id: new FormControl(),
      status: new FormControl(this.ticket.status, [Validators.required]),
      priority: new FormControl(this.appCommon.priorityEnum(
        item => this.ticket.priority),
        [Validators.required]
      ),
      completion_date: new FormControl(this.ticket.completion_date),
      note: new FormControl(null),
      closed: new FormControl(this.ticket.closed)
    });

    this.noteForm = this.formBuilder.group({
      id: new FormControl(),
      // public: new FormControl(false, [Validators.required]),
      public: false,
      note: new FormControl(null, [Validators.required]),
    });

    this.protocolForm = this.formBuilder.group({
      protocol: new FormControl(this.ticket.protocol, [Validators.required]),
      id: new FormControl(this.ticket.id)
    });

    // desabilita os forms antes do loading
    if (this.loading) {
      this.editForm.disable();
      this.noteForm.disable();
    }
  }

  /**
   * Edita o ticket
   */
  onSubmitForm() {
    this.loadingEditForm = true;
    this.editForm.disable();

    const ticket: Ticket = this.editForm.getRawValue();

    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      updateOmbudsmanTicket, ticket)))
      .subscribe(
        resp => {
          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.ticket = result.data.updateOmbudsmanTicket;
          this.ticket.priority = this.appCommon.priorityEnum(this.ticket.priority);

          this.cacheService.update(this.ticket);
          this.resetForm();
          this.checkDisableNote();

          this.loadingEditForm = false;

          this.snackBar.open('Ticket salvo com sucesso.', 'X', {
            duration: 3000,
            panelClass: ['snackBarWhite'],
            horizontalPosition: 'end'
          })
        },
        error => {
          console.log(error);
          this.loadingEditForm = false;
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'X', {
            duration: 3000,
            panelClass: ['snackBarWhite'],
            horizontalPosition: 'end'
          })
        }
      );
  }

  /**
   * Finaliza formulario para enviar uma resposta ou uma observacao
   */
  onSubmitFormNote() {
    // this.loading = true;
    this.spinnerLoading = true;
    this.noteForm.disable();

    const noteObject: any = this.noteForm.getRawValue();

    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      addNoteToTicket, noteObject)))
      .subscribe(
        resp => {
          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.ticket = result.data.addNoteToTicket;

          this.noteForm.reset();
          this.noteForm.controls.id.setValue(this.ticket.id);
          this.noteForm.enable();
          this.checkDisableNote();
          this.spinnerLoading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.spinnerLoading = false;
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'X', {
            duration: 3000,
            panelClass: ['snackBarWhite'],
            horizontalPosition: 'end'
          })
        }
      );
  }

  onSubmitFormProtocol() {
    this.loadingProtocolForm = true;

    const protocolObject = this.protocolForm.getRawValue();

    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      updateOmbudsmanTicketProtocol, protocolObject)))
      .subscribe(resp => {
        this.loadingProtocolForm = false;
        const result: any = resp; // so para corrigir o bug 'Property 'data' does not exist on type '{}' '
        this.ticket = result.data.updateOmbudsmanTicketProtocol;
        this.cacheService.update(this.ticket);

        this.resetForm();

      }, error => {
        console.log(error);
        this.loadingProtocolForm = false;
        this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'X', {
          duration: 3000,
          panelClass: ['snackBarWhite'],
          horizontalPosition: 'end'
        })
      });
  }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AmplifyService} from 'aws-amplify-angular';
import {fromPromise} from 'rxjs/internal-compatibility';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../core/auth/auth.service';

import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../pages/environments/environment';
import {Environment} from '../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;

  errorMessage: string;
  newPasswordErrorMessage: string;
  name = Environment.projectName
  needChangePassword = false;
  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  confirmationCodeForm: FormGroup;

  version = environment.version;
  status = 0

  private cognitoUser: any;

  constructor(
    private router: Router,
    private amplifyService: AmplifyService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar) {


    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.minLength(8)]),
    });
    this.forgetPasswordForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.required]),
    });
    this.confirmationCodeForm = this.formBuilder.group({
      code: new FormControl(null, [Validators.required]),
    });


    fromPromise(this.amplifyService.auth().currentAuthenticatedUser({bypassCache: true}))
      .subscribe((user: any) => {
          // usuario ja esta logado
          this.loginForm.controls.email.setValue(user.attributes.email);
          this.loginForm.controls.password.setValue('12345678'); // senha fake
          this.loginForm.disable();

          this.loading = true;
          this.errorMessage = '';
          this.authService.loadUser(user);
          this.router.navigate(['/home']);
        },
        error => console.log(error)
      );
  }

  ngOnInit(): void {
  }

  onSubmitForm() {
    this.loading = true;
    this.loginForm.disable();

    const loginObject = this.loginForm.getRawValue();

    if (!this.needChangePassword) {
      this.doLogin(loginObject.email, loginObject.password);
    } else {
      this.changeFirstPassword(loginObject.newPassword);
    }
  }

  /***
   * Funções para Redefinir Senha
   */
  onConfirmCode() {
    this.status = 0
    this.openSnackBar('senha Redefinida com Sucesso')
  }

  /***
   * Executa login, e faz validacoes de respostas
   * @param email (string)
   * @param password (string)
   */
  doLogin(email: string, password: string) {
    this.loading = true;
    this.errorMessage = '';

    fromPromise(this.amplifyService.auth().signIn(email, password))
      .subscribe(user => {
          this.cognitoUser = user; // so para corrigir bug 'Property 'challengeName' does not exist on type '{}''
          if (this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.needChangePassword = true;

            this.loginForm.controls.newPassword.setValidators([Validators.required]);
            this.loginForm.controls.newPassword.enable();

            this.loading = false;

          } else {
            this.authService.loadUser(this.cognitoUser);
            this.router.navigate(['/home']);
          }
        },
        error => {
          console.log(error);
          switch (error.code) {
            case 'UserNotFoundException': {
              this.errorMessage = 'Usuário não encontrado.';
              break;
            }
            case 'NotAuthorizedException': {
              switch (error.message) {
                case 'User is disabled':
                  this.errorMessage = 'Usuário desabilitado.';
                  break;
                case 'Password attempts exceeded':
                  this.errorMessage = 'Tentativas de senha excedidas';
                  break;
                case 'User tab-account has expired, it must be reset by an administrator.':
                  this.errorMessage = 'Sua conta expirou. Contate o suporte.';
                  break;

                default:
                  this.errorMessage = 'Usuário ou senha incorreto.';
                  this.loginForm.controls.password.setValue(null);
                  break;
              }
              break;
            }
            case 'InvalidParameterException': {
              this.errorMessage = 'Algo inesperado ocorreu, contate o suporte.';
              break;
            }
            default: {
              break;
            }
          }
          this.loginForm.enable();
          this.loading = false;
        }
      );
  }

  /**
   * Altera a senha de primeiro acesso e continua o login
   * @param newPassword (stirng)
   */
  changeFirstPassword(newPassword: string) {
    this.loading = true;

    fromPromise(this.amplifyService.auth().completeNewPassword(this.cognitoUser, newPassword))
      .subscribe(user => {
          this.authService.loadUser(this.cognitoUser);
          this.router.navigate(['/home']);
        },
        error => {
          console.log(error);
          this.newPasswordErrorMessage = 'Tente novamente mais tarde, ou, contate o suporte.';
          this.loading = false;
        });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 3000
    });
  }

  onRedefinePassword() {
    this.status = 3
  }
}

import { AfterViewInit, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ConnectionCheckService } from "./connection-check-service/connection-check.service";

import { AuthService } from "./core/auth/auth.service";

import { AmplifyService } from "aws-amplify-angular";
import { SwPush } from "@angular/service-worker";

// import {NewsletterService} from './services/newsletterService';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit, OnInit {
  loadingModule = false;
  readonly VAPID_PUBLIC_KEY =
    "BKsG4IQlpDdjA_sFX7ALroZ7h5u1XsnHc2tGO8yqBAHLGSvm6ldWdGd5Fuy8YgVd_Fp1M84X5NJFPOJj0KBgKKU";

  constructor(
    private connectionCheckService: ConnectionCheckService,
    private router: Router,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private domSanitizer: DomSanitizer,
    public amplifyService: AmplifyService,
    public matIconRegistry: MatIconRegistry,
    private swPush: SwPush // private newsletterService: NewsletterService
  ) {
    matIconRegistry.addSvgIcon(
      "delete",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lixo.svg")
    );
    matIconRegistry.addSvgIcon(
      "arrow-down",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/flecha.svg")
    );
    matIconRegistry.addSvgIcon(
      "map",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/map.svg")
    );
    matIconRegistry.addSvgIcon(
      "upload",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/upload-de-arquivo.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "user-menu",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/user-menu.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "painel",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/painel.svg")
    );
    matIconRegistry.addSvgIcon(
      "cognito-user",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/cognito-user.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "menu",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/menu.svg")
    );
    matIconRegistry.addSvgIcon(
      "school",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/school.svg")
    );
    matIconRegistry.addSvgIcon(
      "map-points",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/points.svg")
    );
    matIconRegistry.addSvgIcon(
      "account-group",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/account-group.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "account",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/account.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "responsible-multiple",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/account-group-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "responsible",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/account-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "pencil",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/pencil.svg")
    );
    matIconRegistry.addSvgIcon(
      "eye",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/eye.svg")
    );
    matIconRegistry.addSvgIcon(
      "eye-closed",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/eye-off.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "plus",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/plus.svg")
    );
    matIconRegistry.addSvgIcon(
      "trash-can",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/trash-can-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "lock-reset",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/lock-reset.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "office-building",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/office-building.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "format-list-type",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/format-list-bulleted-type.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "format-list",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/format-list-bulleted.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "alert",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/alert.svg")
    );
    matIconRegistry.addSvgIcon(
      "worker",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/worker.svg")
    );
    matIconRegistry.addSvgIcon(
      "lan",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lan.svg")
    );
    matIconRegistry.addSvgIcon(
      "quiz",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/file-document-box-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "open-in-new",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/open-in-new.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "complaint",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/emoticon-angry-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "compliment",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/emoticon-kiss-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "criticism",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/message-alert-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "suggestion",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/message-settings-variant-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "information",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/information.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "denounce",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/alert-octagram.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "history",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/history.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "timelapse",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/timelapse.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "clock-outline",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/clock-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "folder-multiple-image",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/folder-multiple-image.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "folder-image",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/folder-image.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "content-save",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/content-save.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "note",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/note.svg")
    );
    matIconRegistry.addSvgIcon(
      "question-answer",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/question-answer.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "cards",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/cards.svg")
    );
    matIconRegistry.addSvgIcon(
      "card",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/card.svg")
    );
    matIconRegistry.addSvgIcon(
      "arrow-right",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/arrow-right.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "arrow-drop-down",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/arrow-drop-down.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "empty-occurence",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/empty-occurrence.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "description",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/description.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "searchImage",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/searchImage.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "account-alert",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/mpu.svg")
    );
    matIconRegistry.addSvgIcon(
      "information",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/information.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "info",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/info.svg")
    );
    matIconRegistry.addSvgIcon(
      "help",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/help.svg")
    );
    matIconRegistry.addSvgIcon(
      "hospital",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/hospital.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "download",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/download.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "copy",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/copy-regular.svg"
      )
    );

    // PAGINAS
    matIconRegistry.addSvgIcon(
      "list-occurrences",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/format-list-bulleted.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "mpu",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/mpu.svg")
    );
    matIconRegistry.addSvgIcon(
      "Safe school",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/protective-measure.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "emergency-phones",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/phone-solid.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "list-occurrences-grouped",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/view-list.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "map-occurrences",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/map.svg")
    );
    matIconRegistry.addSvgIcon(
      "map-monitoring",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/map-monitoring.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "map-monitoring",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/map-monitoring.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "ombudsman",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/forum-outline.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "system-user-config",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/account-group.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "offices-config",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/office-building.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "occurrences-type-config",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/format-list-bulleted-type.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "occurrences-status-config",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/debug-step-over.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "integrations-config",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/lan.svg")
    );
    matIconRegistry.addSvgIcon(
      "mobile-message",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/cellphone-message.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "mobile-quiz",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/thumbs-up-down.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "mobile-review",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/chart-line.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "mobile-review-notes",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/mobile-review-notes.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "kanban",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/kanban.svg")
    );
    matIconRegistry.addSvgIcon(
      "vaccination-points",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/vaccination-point.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "vaccine",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/vaccine.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "engine",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/engine.svg")
    );
    matIconRegistry.addSvgIcon(
      "user-priority-status-config",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/user-priority.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "news-feed",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/news-feed.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "send-telegram",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/send-telegram.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "green-point",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/green-point.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "red-point",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/red-point.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "people-confirm",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/people-confirm.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "trash",
      domSanitizer.bypassSecurityTrustResourceUrl("assets/img/icons/trash.svg")
    );
    matIconRegistry.addSvgIcon(
      "graphic",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/graphic.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "protective-measure",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/icons/protective-measure.svg"
      )
    );

    // TIPOS DE OCORRENCIAS
    matIconRegistry.addSvgIcon(
      "type-00",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/00.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-01",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/01.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-02",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/02.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-03",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/03.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-04",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/04.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-05",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/05.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-06",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/06.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-07",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/07.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-08",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/08.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-09",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/09.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-10",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/10.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-11",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/11.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-12",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/12.svg"
      )
    );
    matIconRegistry.addSvgIcon(
      "type-13",
      domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/occurrence-type/13.svg"
      )
    );
  }

  ngOnInit(): void {}

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingModule = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.loadingModule = false;
      }
    });
  }

  // subscribeToNotifications() {
  //   this.swPush.requestSubscription({
  //     serverPublicKey: this.VAPID_PUBLIC_KEY
  //   })
  //     .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
  //     .catch(err => console.error('Could not subscribe to notifications', err));
  // }
}

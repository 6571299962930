import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

import {Ticket} from '../../../shared/model/ticket';
import {AppCommon} from '../../../shared/model/common';
import {Occurrence} from '../../../shared/model/occurrence';
import {GroupedOccurrence} from '../../../shared/model/grouped-occurrence';

import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';

import {graphqlOperation} from 'aws-amplify';

import {AmplifyService} from 'aws-amplify-angular';

import {CacheService} from '../../cache/cache.service';

import {fromPromise} from 'rxjs/internal-compatibility';

import {AuthService} from '../../../core/auth/auth.service';

import {listOccurrencesOfGroup} from '../../../../graphql/queries';
import {updateOccurrencesGrouped} from '../../../../graphql/mutations';

import {ImageDialogComponent} from '../../occurrences/image-dialog/image-dialog.component';
import {OccurrenceBottonSheetComponent} from '../../occurrences/occurrence-botton-sheet/occurrence-botton-sheet.component';
import {UserInfoComponent} from '../../../shared/components/user-info/user-info.component';


@Component({
  selector: 'app-form-occurrences-grouped',
  templateUrl: './form-occurrences-grouped.component.html',
  styleUrls: ['./form-occurrences-grouped.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'dd/mm/AAAA',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'DD/MM/YYYY',
        }
      }
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class FormOccurrencesGroupedComponent implements OnInit {

  static occurrenceGroupedEmitter = new EventEmitter();

  @Input() item: GroupedOccurrence;
  @Input() canEdit = true;
  @Output() occurrencesOfGroup = new EventEmitter();

  childOccurrences: Array<Occurrence | Ticket>;
  spinnerLoading = false;
  loading = true;
  loadingEditForm = false;
  editForm: FormGroup;
  occurrence = new Occurrence();
  statusOptions: Array<{ name: string }>;
  minDate = new Date(2018, 1, 1);
  maxDate = new Date(2120, 12, 31);
  today = new Date();
  edit = false;
  loadingImage = true;
  // date = new FormControl(moment(['dd/mm/yyyy']));
  historic;
  private occurrenceForm;
  autofoco = true;
  minZoom = 7;
  maxZoom = 18;
  zoom = 5;
  listOccurrencesMouseOver: string = null; // ocorrencia na listagem a esquerda que o mouse esta sobre
  officeName;

  isLandscape$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.TabletLandscape, Breakpoints.WebLandscape])
    .pipe(
      map(result => result.matches)
    );
  private occurrenceGroupId: any;
  private occurrencesReset: any;


  constructor(
    private amplifyService: AmplifyService,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    private cacheService: CacheService,
    private appCommon: AppCommon,
    private datePipe: DatePipe,
    private breakpointObserver: BreakpointObserver,
    private bottomSheet: MatBottomSheet,
  ) {
    // busca os status de ocorrencias do usuario e preencher o select
    this.authService.occurrencesStatus.subscribe(status => {
        this.statusOptions = status;
      }
    );

    // busca as telas que o usuario tem permissao
    this.authService.permissions.subscribe(permissions => {
        const permission = permissions.find(item => item.name === 'list-occurrences');
        if (permission) {
          this.canEdit = permission.action === 'rw';
        }
      }
    );

    this.listOccurrences();

  }

  ngOnInit() {
    this.initForm();
  }

  /**
   * Desativa formulario e volta os valores iniciais
   */
  resetForm() {
    this.editForm.reset(Object.assign({}, this.occurrencesReset));
    this.editForm.disable();
  }

  listOccurrences(){
    // Pega id da URL e carrega ocorrencia
    this.activateRoute.params.subscribe(params => {
      const id = params.id;
      this.occurrence.id = id;
      this.occurrenceGroupId = id;

      if (id !== undefined && id !== null) {
        fromPromise(this.amplifyService.api().graphql(graphqlOperation(listOccurrencesOfGroup, {id})))
          .subscribe(
            resp => {
              const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
              this.occurrence = result.data.listOccurrencesOfGroup;
              this.occurrenceForm = this.occurrence;
              this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);
              this.childOccurrences = result.data.listOccurrencesOfGroup.items;
              this.historic = this.childOccurrences[0].historic.length;
              this.officeName = this.childOccurrences[0].office_name;
              this.occurrencesReset = this.occurrenceForm.items[0];
              this.occurrencesReset.priority = this.appCommon.priorityEnum(this.occurrenceForm.items[0].priority);

              FormOccurrencesGroupedComponent.occurrenceGroupedEmitter.emit(this.childOccurrences[0]);
              this.resetForm();

              this.loading = false;

            },
            error => console.log(error)
          );

      } else {
        this.loading = false;
        this.loadingImage = false;
      }

    });
  }

  /**
   * Cria o formulario/controle
   */
  initForm() {
    this.editForm = this.formBuilder.group({
      id: new FormControl(),
      status: new FormControl(this.occurrence.status, [Validators.required]),
      priority: new FormControl(this.appCommon.priorityEnum(this.occurrence.priority), [Validators.required]
      ),
      completion_date: new FormControl(this.occurrence.completion_date),
      note: new FormControl(null),
      closed: new FormControl(this.occurrence.closed),
    });

    // desabilita os forms antes do loading
    if (this.loading) {
      this.editForm.disable();
    }
  }

  onSubmitForm() {
    this.loadingEditForm = true;
    this.editForm.disable();

    const occurrence: Occurrence = this.editForm.getRawValue();
    occurrence.id = this.occurrenceGroupId;
    fromPromise(this.amplifyService.api().graphql(graphqlOperation(
      updateOccurrencesGrouped, occurrence)))
      .subscribe(
        resp => {
          const result: any = resp; // so para corrigir bug 'Property 'data' does not exist on type '{}''
          this.occurrence = result.data.updateOccurrencesGrouped;
          this.occurrence.priority = this.appCommon.priorityEnum(this.occurrence.priority);
          this.cacheService.update(this.occurrence);
          this.loadingEditForm = false;
          this.listOccurrences();

          this.snackBar.open('Ocorrência salva com sucesso.', 'X', {
            duration: 3000,
            panelClass: ['snackBarWhite'],
            horizontalPosition: 'end'
          })
        },
        error => {
          console.log(error);
          this.loadingEditForm = false;
          this.snackBar.open('Erro ao salvar, se o problema persistir contate o suporte.', 'X', {
            duration: 3000,
            panelClass: ['snackBarWhite'],
            horizontalPosition: 'end'
          })
        }
      );
  }

  saveDate() {
    return this.datePipe.transform(this.editForm.get('completion_date').value, 'dd/MM/yyyy') <
      this.datePipe.transform(this.today, 'dd/MM/yyyy');
  }

  /**
   * converte o LAT e LONG para string
   * @param value lat e long do MAPS
   */
  convertString(value): number {
    return parseFloat(value);
  }

  formDisable() {
    if (this.occurrence.status === this.editForm.controls.status.value) {
      this.editForm.disable();
    } else {
      this.editForm.enable();
    }

  }

  openImage(urlImage: string) {
    this.dialog.open(ImageDialogComponent, {
      data: urlImage
    });
  }

  getIcon(icon: string) {
    return 'assets/img/occurrence-type/marker-' + icon + '.svg';
  }

  openOccurrence(occurrence: Occurrence): void {
    this.bottomSheet.open(OccurrenceBottonSheetComponent, {
      data: occurrence
    });
  }

  openUserInfo(occurrence: Occurrence){
    this.bottomSheet.open(UserInfoComponent, {
      data: occurrence
    });
  }

}

<app-main-nav-web
  [pagesPermission]="pagesPermission"
  *ngIf="isLandscape$ | async; else web"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</app-main-nav-web>

<ng-template #web>
  <app-main-nav-web [pagesPermission]="pagesPermission">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-main-nav-web>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

export const Environment = {
  projectName: 'Escolas Seguras 1.0'
}

export const awsConfig = {
  "aws_project_region": "us-east-2",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_WoTPNs0jN",
  "aws_user_pools_web_client_id": "2ofljrlfbgfteiapnocd4ugmft",
  "oauth": {},
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://ypiemxiz4ffxzoxqkvi4weksye.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-rxhybrvfyrheffez6znfsov5xy"
};



const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:cfb53fff-5215-4d22-9774-6e46f920b4f3",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_zPd8ms7Iv",
  "aws_user_pools_web_client_id": "6526hs78pflm4phtrl8cr5j645",
  "oauth": {},
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://uaa2mqgkafaldnt6a6j7hq2u5y.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-bmps2jnilreqtnotyqjod3wm2i"
};

export const configs ={
  projectName: "Escolas Seguras",
  initialLat: -30.041306,
  initialLng: -51.1949079
}

export default awsmobile;
